<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">RESULTADOS DE LAS ENCUESTAS DE EVALUACIÓN REALIZADAS:
                    </span>
                    <hr>
                    <CChartPie :options="optionsEstadistica" class="my-2" :datasets="dataSetsEstadistica" :labels="labelEstadistica" />
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i><span class="h5"> Gestión de incidencias de proveedores</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA EL ÁREA DE EVALUACIÓN DE PROVEEDORES:
                    </span>
                    <hr>
                    <b-row class="content-justify-center text-center">
                        <b-col cols="6" class=" my-2 icon-custom vr">
                            <i style="inline-size: auto" class=" ico fas fa-edit fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaEvaluaciones.length}}</span>
                            <br>
                            <span class="text-muted">Cuestionarios registrados</span>
                        </b-col>
                        <b-col cols="6" class="text-center my-2 icon-custom">
                            <i style="inline-size: auto" class=" ico fas fa-star-half-alt fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">0 %</span>
                            <br>
                            <span class="text-muted">Evaluación media</span>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row class="text-center justify-content-center">
                        <b-col md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{name: 'Proveedores'}">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                            </b-button>
                        </b-col>
                        <b-col md="4" v-if="checkPermissions('009-REC-PRO-CUE','c')==1" class="my-2">
                            <b-button block size="md" variant="custom" @click="modalEnvioCuestionario = true">
                                <i style="inline-size: auto" class="fas fa-envelope fa-3x pb-1"></i><br>Envio
                            </b-button>
                        </b-col>
                        <b-col v-if="checkPermissions('009-REC-PRO-PRE','r')==1" md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{name: 'Configuracion Evaluacion'}">
                                <i style="inline-size: auto" class="fas fa-question-circle fa-3x pb-1"></i><br>Configurar preguntas
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-external-link-alt fa-md"></i><span class="h5"> Enlace a cuestionario</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">Proporcione el siguiente enlace a sus proveedores para que accedan a su cuestionario:
                        <!-- <a target="_blank" href="https://sig-software.com/cuestionario-de-evaluacion-proveedores/?ej=2021">https://sig-software.com/cuestionario-de-evaluacion-proveedores/?ej=2021</a></span> -->
                        <a target="_blank" :href="`${path}cuestionarios/evaluacion-del-proveedor/ejemplo/${datosEnvioCuestionario.idCliente}`">Demostración de cuestionario</a></span>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Cuestionarios de evaluación registrados </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table hover bordered show-empty mediun responsive outlined :items="listaEvaluaciones" :fields="camposEvaluaciones" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button :to="{name:'Gestion Evaluacion Proveedores', params: {id: param.item.idEvaluacionProveedor}}" size="sm" v-c-tooltip.hover.click="'Ver detalles'" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button :disabled="disabled" @click="generarReporte(param)" size="sm" v-c-tooltip.hover.click="'Descargar'" variant="blue" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-download my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <!-- <b-button v-if="param.item.urlReporte !=null" @click="descargarDocumento(param.item.urlReporte)" size="sm" v-c-tooltip.hover.click="'Descargar'" variant="success" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                           <i class="fas fa-download my-0 mx-0"></i>
                                        </span>
                                    </b-button> -->
                                    <b-button v-if="checkPermissions('009-REC-PRO-CUE','d')==1" @click="eliminarEvaluacion(param)" size="sm" v-c-tooltip.hover.click="'Eliminar'" variant="danger" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalEnvioCuestionario" :closeOnBackdrop="false" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-envelope fa-md"></i>
                <span class="h5"> Gestión de proveedores</span></h6>
            <CButtonClose @click="modalEnvioCuestionario = false" class="text-white" />
        </template>
        <b-row>
            <b-col md="12">
                <b-form-group label="Proveedores:">

                    <span v-for="items in optionsProveedores" :key="items.index">
                        <b-form-checkbox plain stacked v-model="datosEnvioCuestionario.proveedoresSeleccionados" :value="items">
                            {{items.razonSocial}} <span class="text-muted ml-2" v-if="items.fecha != null">Último envio: {{items.fecha}}</span></b-form-checkbox>
                    </span>
                </b-form-group>
            </b-col>
            <b-col lg="12" class="text-right my-2">
                <b-button size="md" variant="success" class=" mr-2" @click="enviarCorreos()">
                    Enviar
                </b-button>
                <b-button size="md" variant="danger" @click="modalEnvioCuestionario = false">
                    Cancelar
                </b-button>
            </b-col>
        </b-row>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import {
    CChartPie
} from '@coreui/vue-chartjs'

import Docxtemplater from "docxtemplater";
import {
    saveAs
} from "file-saver";
import ImageModule from "docxtemplater-image-module-free";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";

import expressions from 'angular-expressions';
import assign from "lodash.assign";

expressions.filters.lower = function (input) {
    // This condition should be used to make sure that if your input is
    // undefined, your output will be undefined as well and will not
    // throw an error
    if (!input) return input;
    return input.toLowerCase();
}

function angularParser(tag) {
    if (tag === '.') {
        return {
            get: function (s) {
                return s;
            }
        };
    }
    const expr = expressions.compile(
        tag.replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"')
    );
    return {
        get: function (scope, context) {
            let obj = {};
            const scopeList = context.scopeList;
            const num = context.num;
            for (let i = 0, len = num + 1; i < len; i++) {
                obj = assign(obj, scopeList[i]);
            }
            return expr(scope, obj);
        }
    };
}

export default {
    components: {
        CChartPie
    },
    data() {
        return {
            path: CONSTANTES.URL_RUTA_FRONTEND+'#/',
            disabled: false,
            listaPreguntasEvaluacion: [],
            datosEnvioCuestionario: {
                idCliente: '',
                proveedoresSeleccionados: [],
            },
            labelEstadistica: ["Muy mal", "Mal", "Regular", "Bien", "Excelente"],
            optionsEstadistica: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },
            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            optionsProveedores: [],

            listaEvaluaciones: [],
            camposEvaluaciones: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "razonSocial",
                    label: "Proveedor",
                    class: "text-center"
                },
                {
                    key: "valoracion",
                    label: "Valoración",
                    class: "text-center"
                },
                {
                    key: "fechaCompletado",
                    label: "Fecha",
                    class: "text-center"
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                },
            ],
            modalEnvioCuestionario: false,
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        base64DataURLToArrayBuffer(dataURL) {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        registrarIncidencia() {},
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        listarProveedores() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-proveedores-correo", {
                        params: {
                            idCliente: me.datosEnvioCuestionario.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.optionsProveedores = response.data.filter(x => x.email != '');
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        generarReporte(param) {
            let me = this;

            me.disabled = true;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-logo-cliente", {
                        params: {
                            logo: JSON.parse(localStorage.settings).logo
                        }
                        /* proveedor: param.item.razonSocial,
                        fecha: param.item.fechaCompletado,
                        observacion: param.item.observacion, */

                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    //me.listarEvaluaciones();
                    let listaPreguntas = me.listaPreguntasEvaluacion.filter(x => x.idEvaluacionProveedor == param.item.idEvaluacionProveedor)
                    /* let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje) */

                    JSZipUtils.getBinaryContent(`${CONSTANTES.URL_RUTA_FRONTEND}plantillas/plantilla-evaluacion-proveedor.docx`, function (error, content) {
                        if (error) {
                            console.error(error);
                            return;
                        }
                        var opts = {}
                        opts.centered = true;
                        const imageOpts = {
                            getImage(tag) {
                                return me.base64DataURLToArrayBuffer(tag);
                            },
                            getSize() {
                                return [150, 60];
                            },
                        };

                        var imageModule = new ImageModule(imageOpts);

                        var zip = new JSZip(content);
                        var doc = new Docxtemplater()
                            .loadZip(zip)
                            .setOptions({
                                parser: angularParser
                            })
                            .attachModule(imageModule);
                        doc.setData({
                            //image: response.data.datosAsistentes[0].urlFirma,
                            logo: response.data[0].logo,
                            fecha: param.item.fechaCompletado,
                            proveedor: param.item.razonSocial,
                            comentario: param.item.observacion,
                            lista: listaPreguntas,
                        });

                        try {
                            doc.render();
                        } catch (error) {
                            let e = {
                                message: error.message,
                                name: error.name,
                                stack: error.stack,
                                properties: error.properties
                            };
                            console.log(e)
                            throw error;
                        }
                        var out = doc.getZip().generate({
                            type: "blob",
                            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        });
                        saveAs(out, "evaluacion-proveedores.docx");
                        //me.disabled = false;
                    });

                    me.disabled = false;
                    // window.open(response.data.enlace[0])
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        descargarDocumento(url) {
            window.open(url)
        },
        listarEvaluaciones() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-evaluaciones-proveedor", {
                        params: {
                            idCliente: me.datosEnvioCuestionario.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaEvaluaciones = response.data;
                    me.filasTotales = me.listaEvaluaciones.length
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        obtenerPreguntasEvaluacion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-preguntas-evaluacion-proveedor", {
                        params: {
                            idCliente: me.datosEnvioCuestionario.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    for (let i in response.data) {
                        me.listaPreguntasEvaluacion.push({
                            idEvaluacionProveedor: response.data[i].idEvaluacionProveedor,
                            pregunta: response.data[i].pregunta,
                            valoracion: response.data[i].tipoPregunta == 2 ? parseInt(response.data[i].valoracion) + ' Estrellas' : parseInt(response.data[i].valoracion) == 1 ? 'SI ' : 'NO',
                            nombreArchivo: response.data[i].urlArchivo ? response.data[i].nombreArchivo : ' ',
                        })
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = true;
                });
        },
        enviarCorreos() {
            if (this.datosEnvioCuestionario.proveedoresSeleccionados.length < 1) {
                this.swat('error', 'Debes seleccionar al menos un proveedor')
                return;
            } else {
                let me = this;
                axios
                    .post(
                        CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-evaluacion-proveedor",
                        me.datosEnvioCuestionario, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`
                            },
                        }
                    )
                    .then(function (response) {
                        me.modalEnvioCuestionario = false
                        let color = response.data.resultado == 1 ? "success" : "error";
                        me.swat(color, response.data.mensaje)
                        me.listarProveedores();
                    })
                    .catch(function (error) {
                        me.swat('error', 'Algo salió mal!')
                    });
            }
        },
        eliminarEvaluacion(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la evaluación?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-evaluacion-proveedor", {
                                idEvaluacionProveedor: param.item.idEvaluacionProveedor
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarEvaluaciones();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },

        resetModalEnvioCuestionario() {
            this.datosEnvioCuestionario.proveedoresSeleccionados = [];
            this.modalEnvioCuestionario = false
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        dataSetsEstadistica() {
            return [{
                borderWidth: 1,
                borderWidth: 3,
                backgroundColor: [
                    '#f16553d4',
                    '#fdcd60cc',
                    '#ccccccdb',
                    '#6bba70db',
                    '#428bca94',
                ],
                data: [3, 6, 4, 5, 2]
            }]
        },

    },
    watch: {
        modalEnvioCuestionario: function (val) {
            if (val == false) {
                this.resetModalEnvioCuestionario();
            }
        },
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosEnvioCuestionario.idCliente = user.uidClient;
            this.listarProveedores();
            this.listarEvaluaciones();
            this.obtenerPreguntasEvaluacion();
        }
    }

}
</script>
